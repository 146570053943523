<div class="outer-container">
  <div class="vus-container">
    <label
      class="modal-title space-bottom">{{ 'modules.admin-section.testautomation.test-history.title' | transloco }}</label>
    <ngx-datatable
      #dataTable
      class="material"
      [columnMode]="'flex'"
      [headerHeight]="56"
      rowHeight="auto"
      [rows]="testRuns"
      [sorts]="sortConfig">

      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-timestamp' | transloco }}"
        prop="timeStamp"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value"> {{ value | date: "dd.MM.yy, HH:mm:ss" }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-duration' | transloco }}"
        prop="duration"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span>{{ formatTime(row, value) }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-succeeded' | transloco }}"
        prop="successfulTestCases"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <div class="status-box">
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <span *ngIf="value">
            <span
              [ngStyle]="{'color': StatusColor.GREEN}"
            >
              <i class="bi bi-circle-fill"></i>
            </span>
              {{ value }}</span>
            <span *ngIf="!value" class="text-center"> - </span>
          </ng-template>
        </div>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-failed' | transloco }}"
        prop="unsuccessfulTestCases"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">
          <span
            [ngStyle]="{'color': StatusColor.RED}"
          >
            <i class="bi bi-circle-fill"></i>
          </span>

            {{ value }}</span>
          <span *ngIf="!value" class="text-center"> - </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-user' | transloco }}"
        prop="username"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">{{ value }} </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-status' | transloco }}"
        prop="status"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value === 'success'">
            <i class="bi bi-check-lg"></i>&nbsp;&nbsp;
            {{ 'modules.admin-section.testautomation.test-history.test-status-success' | transloco }}
          </span>
          <span *ngIf="value === 'cancelled'">
            <i class="bi bi-x-lg"></i>&nbsp;&nbsp;
            {{ 'modules.admin-section.testautomation.test-history.test-status-cancelled' | transloco }}
          </span>
          <span *ngIf="value === 'failed'">
            <i class="bi bi-x-lg"></i>&nbsp;&nbsp;
            {{ 'modules.admin-section.testautomation.test-history.test-status-failed' | transloco }}
          </span>
          <span *ngIf="value === 'running'">
            <i class="bi bi-hourglass-bottom"></i>&nbsp;&nbsp;
            {{ 'modules.admin-section.testautomation.test-history.test-status-running' | transloco }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [cellClass]="'text-center'"
        name="{{ 'global.actions' | transloco }}"
        [flexGrow]="2"
        [sortable]="false"
        [resizeable]="false"
      >
        <ng-template
          let-rowIndex="rowIndex"
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
            <span 
              [ngClass]="{'no-drop-cursor': isStatusCompleted(row)}"
              title="{{ 'global.actions' | transloco }}"
            >
                <span 
                    [ngClass]="{'disabled': isStatusCompleted(row)}"
                    (click)="detail(row)"
                >
                    <i class="bi bi-eye-fill"></i>
                </span>
            </span>
            &nbsp; &nbsp;
            <span 
            [ngClass]="{'no-drop-cursor': isStatusCompleted(row)}"
            title="{{'global.actions' | transloco}}"
            >
                <span 
                    [ngClass]="{'disabled': isStatusCompleted(row)}"
                    (click)="download(row)"
                >
                    <i class="bi bi-download"></i>
                </span>
             </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

