<div
  *ngIf="formLoaded"
  class="outer-container sub-section"
  id="flashware-section"
>
  <div>
    <span class="title">{{
        "modules.expert-tool.flashware-detection-title" | transloco
      }}</span>
    <span
      class="caret-spacer"
      (click)="isSectionCollapsed = !isSectionCollapsed"
    >
      <span *ngIf="isSectionCollapsed"><i class="bi bi-caret-down-fill"></i></span>
      <span *ngIf="!isSectionCollapsed"><i class="bi bi-caret-up-fill"></i></span>
    </span>
  </div>
  <form [formGroup]="form" [ngbCollapse]="!isSectionCollapsed">
    <div>
      <div class="row row-cols-auto">
        <div class="col log-level">
          <div class="form-group">
            <label for="logLevel">{{
                "modules.expert-tool.log-level" | transloco
              }}</label>
            <ng-select
              [searchable]="false"
              [hideSelected]="true"
              [items]="logLevelItems"
              bindLabel="name"
              bindValue="name"
              formControlName="logLevel"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }} - {{ item.description | transloco }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.name }} - {{ item.description | transloco }}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="fileExt">{{
                "modules.expert-tool.flash-file-extension" | transloco
              }}</label>
            <ng-select
              [searchable]="false"
              [hideSelected]="true"
              [items]="flashFileExtItems"
              [ngClass]="{ 'border-red': checkUnselected() }"
              bindLabel="name"
              bindValue="name"
              formControlName="fileExt"
              (change)="handleExtentionChange($event)"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col">
          <div class="form-group bdp-box">
            <label for="sdpName">{{
                "modules.expert-tool.data-package-calculation" | transloco
              }}</label>
            <div
              *ngIf="!selectedSdpName"
              class="btn-custom lg-size"
              [ngClass]="{ 'border-red': !selectedSdpName }"
              (click)="openModal()"
            >
              <span class="">{{
                  "modules.expert-tool.btn-add-data-package-calculation"
                    | transloco
                }}</span>
              <i class="bi bi-plus"></i>
            </div>
            <input
              type="text"
              autocomplete="off"
              class="form-control normal-size"
              id="sdpName"
              *ngIf="selectedSdpName"
              formControlName="sdpName"
              readonly
              (click)="openModal()"
            />
              <i
                (click)="openModal()"
                *ngIf="selectedSdpName"
                class="bi bi-plus"></i>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="xentryReleases">{{
                "modules.expert-tool.xentry-release" | transloco
              }}</label>
            <ng-select
              [searchable]="false"
              notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
              [hideSelected]="true"
              [items]="xentryReleasesItems"
              bindLabel="name"
              bindValue="name"
              formControlName="xentryReleases"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="">{{
                "modules.expert-tool.vedoc-data" | transloco
              }}</label>
            <div class="btn-custom sm-size" (click)="handleCheck()">
              <span class="">{{
                  "modules.expert-tool.vedoc-data-query" | transloco
                }}</span>
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  formControlName="abfragen"
                  (change)="handleCheck()"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="flashFullVehicle">{{
              "modules.expert-tool.flash-full-vehicle" | transloco
              }}</label>
            <div class="btn-custom sm-size" (click)="handleFlashFullVehicleCheck()">
              <span class="flashFullVehicleTag">{{
                  "modules.expert-tool.flash-full-vehicle-tag" | transloco
                }}</span>
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    [ngbTooltip]="'modules.expert-tool.flash-full-vehicle-tooltip'| transloco"
                    tooltipClass="note-tooltip"
                    formControlName="flashFullVehicle"
                    (change)="handleFlashFullVehicleCheck()"
                  />
                  <span class="checkmark"></span>
                </label>
            </div>
          </div>
        </div>

          <div *ngIf="sumsCheckVisible" class="col">
            <div class="form-group">
              <label for="">{{
                  "modules.expert-tool.sums-validation-check" | transloco
                }}</label>
              <div class="btn-custom m-size" (click)="handleSumsCheck()">
              <span class="">{{
                  "modules.expert-tool.sums-check-activate" | transloco
                }}</span>
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    formControlName="aktivieren"
                    (change)="handleSumsCheck()"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
